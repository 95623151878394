import * as React from "react";
import { graphql } from "gatsby"
import Layout from "@components/layout";
import SEO from "@components/seo";
import AboutUsComponent from "@components/AboutUs"
import { MetadataService } from "../utils/MetadataService";

const AboutUsPage = ({ uri, data }) =>{
  const { pageMetadata } = data;
  const metadata = MetadataService.parseMetadata(JSON.parse(JSON.stringify(pageMetadata || {}))).content;

  return (
    <Layout path={uri}>
      <SEO title={metadata.page_title} />
      <AboutUsComponent metadata={metadata}/>
      <p>
      </p>
    </Layout>
  );
} 


export const pageQuery = graphql`
  query aboutUsComponentQuery {
    pageMetadata(page_id: {eq: "aboutUs"}) {
      content,
      page_id
    }
  }
`
export default AboutUsPage;
