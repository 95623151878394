// extracted by mini-css-extract-plugin
export const about_us = "AboutUs-module--about_us--1HQeL";
export const about_us_card = "AboutUs-module--about_us_card--1ozgZ";
export const based_out_of = "AboutUs-module--based_out_of--2b-Sf";
export const us_map = "AboutUs-module--us_map--orGgF";
export const what_we_do = "AboutUs-module--what_we_do--1cORB";
export const services = "AboutUs-module--services--3F4Lt";
export const service = "AboutUs-module--service--3ZYxl";
export const wiggleRight = "AboutUs-module--wiggle-right--3rcDp";
export const find_out_link = "AboutUs-module--find_out_link--3FI8b";
export const values_section = "AboutUs-module--values_section--1Z-q2";
export const values = "AboutUs-module--values--W1sC0";
export const value = "AboutUs-module--value--3845S";
export const capital_letter = "AboutUs-module--capital_letter--1YKTO";
export const value_img = "AboutUs-module--value_img--3LXyr";
export const team = "AboutUs-module--team--Rk4fn";
export const members_list = "AboutUs-module--members_list--yy-35";
export const members_img = "AboutUs-module--members_img--3xmCG";
export const bouncy = "AboutUs-module--bouncy--bZTDA";
export const pop = "AboutUs-module--pop--32lJP";