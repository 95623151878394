import * as React from "react";
import { Link } from "gatsby";
import ContactForm from "@components/ContactForm";
import * as styles from "./AboutUs.module.scss";

import analysts from "@images/about_us/analysts.svg";
import architects from "@images/about_us/architects.svg";
import designers from "@images/about_us/designers.svg";
import developers from "@images/about_us/developers.svg";
import marketers from "@images/about_us/marketers.svg";
import engineers from "@images/about_us/engineers.svg";

import websites from "@images/index_page/icon_websites.svg";
import apps from "@images/index_page/icon_apps.svg";
import engagement from "@images/index_page/icon_engagement.svg";
import eCommerce from "@images/index_page/icon_e_commerce.svg";

import logo1 from "@images/about_us/logo_1.svg";
import logo2 from "@images/about_us/logo_2.svg";
import usMap from "@images/about_us/us_map.svg";

import teamImg from "@images/graphics/team_orange.svg";

const IMAGES = {
  "developers": developers,
  "architects": architects,
  "engineers": engineers,
  "designers": designers,
  "analysts": analysts,
  "marketers": marketers,
  "engagement": engagement,
  "apps": apps,
  "websites": websites,
  "eCommerce": eCommerce
}

const ValueCard = props => {
  const { title, text, i } = props;
  return (
    <>
      <h3>
        <span className={styles.capital_letter}>{title.slice(0, 1)}</span>
        {title.slice(1)}
      </h3>
      <p>{text}</p>
    </>
  );
};

const AboutUsComponent = ({metadata}) => {
  return (
    <>
      <div className={`${styles.about_us} container`}>
        <div className={styles.about_us_card}>
          <h1>{metadata.title_1}</h1>
          <img
            className={styles.us_map}
            alt="Magyk location in US"
            src={usMap}
          />
          <p>
            {metadata.page_intro}
          </p>
          <h2 className={styles.based_out_of}>{metadata.title_2}</h2>


        </div>

        <section className={styles.what_we_do}>
          <h2>{metadata.subtitle_1}</h2>
          <div className={styles.services}>
            {metadata.services.map((service, i) => (
              <Link to={service.title==="Engagement" ? "/services/engagement" : "/services"} className={styles.service} key={i}>
                <h3>{service.title}</h3>
                <img src={IMAGES[service.image]} alt={service.title} />
              </Link>
            ))}
          </div>
        </section>

        <section className={styles.values_section}>
          <h2>{metadata.subtitle_2}</h2>
          <div className={styles.values}>
            {metadata.values.map((value, i) => (
                <div className={styles.value} key={i}>
                  <ValueCard {...value} key={i} i={i} />
                </div>
            ))}
            <img
              src={logo2}
              className={styles.value_img}
              key="img2"
              alt="Magyk logo"
            />
          </div>
        </section>

        <section className={styles.team}>
          <h2>{metadata.subtitle_3}</h2>
          <p>
            {metadata.team_text}
          </p>
          <div className={styles.members_list}>
            <ul>
              {metadata.team.map((member, i) => (
                <li key={i}>
                  {member.member}
                  <img src={IMAGES[member.image]} alt={member.member} />
                </li>
              ))}
            </ul>
          </div>
          <img className={styles.members_img} src={teamImg} alt="Members" />
        </section>

        <section style={{ marginTop: "80px" }} className={styles.contact_form}>
          <ContactForm />
        </section>
      </div>
    </>
  );
};

export default AboutUsComponent;
